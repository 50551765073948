import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Fetch.css';

function Fetch() {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [balance, setBalance] = useState(null);
  const [errmsg, setErrmsg] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await axios.post('https://bitworldeth.com/user/api/login', {
        countryId: 45,
        userName: userName,
        password: password
      }, { withCredentials: true }); // added withCredentials option
      const balance = result.data.data.currencyRate * result.data.data.balance;
      setBalance(balance);
    } catch (error) {
      console.log(error);
      setBalance(null);
      setErrmsg('Password or Username Error');
    
    }
    setLoading(false);
  };

  return (
    <div className="fetch-container">
      <form onSubmit={handleSubmit}>
        <label>
          Username:
          <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} />
        </label>
        <br />
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <br />
        <button type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Submit'}
        </button>
      </form>
      {balance !== null ? (
        <div className="balance">Your balance is: {balance}</div>
      ) : (
        <div className="balance">{errmsg !== '' ? `Error: ${errmsg}` : ''}</div>
      )}
    </div>
  );
}

export default Fetch;
