import React, { useState } from 'react';
import RegistrationForm from './RegistrationForm';
import Fetch from './Fetch';
import './App.css'
function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);

  const handleInput = (event) => {
    if (event.target.value === "helloworld") {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }

  //https://bitworldeth.com/user/api/member/mining/income
  return (
    <div>
      <h1 style={{textAlign: 'center'}}>Enter the phrase to access</h1>
      <input type="password" onChange={handleInput} />
      {isAuthorized && (
        <>
          <h1 style={{textAlign: 'center'}}>Check Balances</h1>
          <Fetch></Fetch>
          <h1 style={{textAlign: 'center'}}>Earn More</h1>
          <RegistrationForm></RegistrationForm>
        </>
      )}
    </div>
  );
}

export default App;
