import React, { useState } from 'react';
import './RegistrationForm.css';
import Fetch from './Fetch';

function RegistrationForm() {
  const [mobile, setMobile] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [responseTable, setResponseTable] = useState([]);

  const generateRandomIp = () => {
    const ip1 = Math.floor(Math.random() * 256);
    const ip2 = Math.floor(Math.random() * 256);
    const ip3 = Math.floor(Math.random() * 256);
    const ip4 = Math.floor(Math.random() * 256);
    return `${ip1}.${ip2}.${ip3}.${ip4}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requestBody = {
      countryId: '45',
      inviteCode,
      password: 'dead@body'
    };

    // Send 40 POST requests with incremented mobile numbers and random IP addresses
    for (let i = 0; i < 20; i++) {
      requestBody.mobile = String(parseInt(mobile) + i);
      const ip = generateRandomIp();

      const response = await fetch('https://bitworldeth.com/user/api/member/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Forwarded-For': ip
        },
        body: JSON.stringify(requestBody)
      });

      const responseData = await response.json();

      const responseObj = {
        serialNo: i + 1,
        mobile: requestBody.mobile,
        ipAddress: ip,
        status: response.ok ? 'success' : 'failed',
        message: response.statusText
      };

      setResponseTable(prevTable => [...prevTable, responseObj]);

      if (response.ok) {
        console.log(`Registration ${i + 1} successful!`);
      } else {
        console.error(`Registration ${i + 1} failed:`, response.statusText);
      }
    }
  };

  return (
    <div className="registration-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="mobile">Mobile number:</label>
          <input
            type="text"
            id="mobile"
            value={mobile}
            onChange={(event) => setMobile(event.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="inviteCode">Invite code:</label>
          <input
            type="text"
            id="inviteCode"
            value={inviteCode}
            onChange={(event) => setInviteCode(event.target.value)}
          />
        </div>

        <button type="submit" className="btn-register">Register</button>
      </form>

      <div className="response-table">
        <table>
          <thead>
            <tr>
              <th>Serial No.</th>
              <th>Mobile number</th>
              <th>IP Address</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {responseTable.map((response, index) => (
              <tr key={index}>
                <td>{response.serialNo}</td>
                <td>{response.mobile}</td>
                <td>{response.ipAddress}</td>
                <td>{response.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RegistrationForm;
